<template>
  <el-dialog
      width="650px"
      top="2vh"
      :title="$t('myRegistration')"
      :visible.sync="visible"
      :before-close="close"
    >
    <div class="dialogBox">
      <div class="leftBox">
        <div>{{$t('WCompanyName')}}</div>
        <div>{{$t('Wuaddress')}}</div>
        <div>{{$t('contcat')}}</div>
        <div>{{$t('position')}}</div>
        <div>{{$t('gender')}}</div>
        <div>{{$t('email')}}</div>
        <div>{{$t('phone')}}</div>
        <div>{{$t('Entrytype')}}</div>
        <div>{{$t('RegisterStatus')}}</div>
      </div>
      <div class="rightBox">
        <div>{{ info.company_name_en | priorFormat(info.company_name_zh, LOCALE) }}</div>
        <div>{{ info.company_region_en | priorFormat(info.company_region_zh, LOCALE) }}</div>
        <div>{{ info.name_en | priorFormat(info.name_zh, LOCALE) }}</div>
        <div>{{ info.job_title_en | priorFormat(info.job_title_zh, LOCALE) }}</div>
        <div>{{ info.sex === 1? $t('male') : $t('female') }}</div>
        <div class="email">{{ info.email | textFormat }}</div>
        <div>{{ info.mobile | textFormat }}</div>
        <div>{{ info.user_apply_type | userApplyType }}</div>
        <span
            class="AuditStatus"
            :class="{
              'AuditStatusBg0': info.audit_status === 0,
              'AuditStatusBg1': info.audit_status === 1,
              'AuditStatusBg2': info.audit_status === 2,
               }"
        >{{ info.audit_status | auditStatus }}</span>
      </div>
    </div>
    </el-dialog>
</template>

<script>
  let that
  export default {
    name: 'applyInfoDialog',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
    },
    filters: {
      // 审核状态
      auditStatus(val) {
        let str = '-'
        switch (val) {
          case 0:
            str = that.$t('RegisterWait');
            break;
          case 1:
            str = that.$t('RegisterWaitpass');
            break;
          case 2:
            str = that.$t('RegisterRefuse');
            break;
        }
        return str
      },
      userApplyType(val) {
        let str = '-'
        switch (val) {
          case 1:
            str = that.$t('onlineExhibition');
            break;
          case 2:
            str = that.$t('offlineExhibition');
            break;
          case 3:
            str = that.$t('payingVisitor');
            break;
          case 4:
            str = that.$t('freeVisitor');
            break;
        }
        return str
      }
    },
    beforeCreate: function () {
      that = this;
    },
    computed: {
      info() {
        let obj = Object.assign(this.USER_INFO, this.ENROLL_INFO)
        return obj
      }
    },
    methods: {
      close() {
        this.$emit('update:visible', false)
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .dialogBox {
    padding-left: 100px;
    display: flex;
    .leftBox {
      div {
        width: 200px;
        color: #909399;
        font-size: 16px;
        margin-top: 21px;
      }
    }
    .rightBox {
      div {
        margin-top: 21px;
        color: #1F292E;
        font-size: 16px;
        width: 310px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .email {
        color: #2292FF;
      }
      .AuditStatus {
        display: inline-block;
        padding: 5px 14px;
        font-weight: 500;
        border-radius: 4px;
        margin-top: 21px;
      }
      // 待审核
      .AuditStatusBg0 {
        background: #FFDDDD;
        color: #FA1919;
      }
      // 审核通过
      .AuditStatusBg1 {
        background: #FFDDDD;
        color: #FA1919;
      }
      //  审核拒绝
      .AuditStatusBg2 {
        background: #FFDDDD;
        color: #FA1919;
      }
    }
  }
  /deep/ .el-dialog__body {
    padding: 15px 20px 73px;
  }
</style>

