<template>
    <div>
        <banner></banner>
        <div class="VisitExhibition">
            <!-- 线上观展  线下观展 -->
            <div class="guanzhan">
                <div class="left">
                    <div class="header">
                        <img :src="offline" alt="">
                        <span>{{ title.offline }}</span>
                    </div>
                    <div class="desc">{{ title.desc }}</div>
                    <div class="c_box"   >
                        <div class="c_item" v-for="(item,m) in content.left" :key="m">
                            <div>
                                <i class="el-icon-check"></i>
                            </div>
                            <span>{{item}}</span>
                        </div>
                        <div class="dashed" style="height: 16px;"></div>
                        <!-- 早鸟票价  优惠票价 -->
                        <div class="price_box" v-if="false">
                            <div class="price_item">
                                <div class="lable">
                                    <span>{{ this.$t('VisitExhibition_Early_bird') }} (</span><span style="color:#2292FF">{{ $t('time_before_1_31')  }}</span><span>)</span>
                                </div>
                                <div class="item">
                                    <div>
                                        <p>{{ $t('VisitExhibition_gn_price') }}</p>
                                        <small>¥</small> 
                                        <span>1800</span>
                                        <small>RMB</small>
                                    </div>
                                    <div>
                                        <p>{{ $t('VisitExhibition_hw_price') }}</p>
                                        <small>$</small> 
                                        <span>270</span>
                                        <small>USD</small>
                                    </div>
                                </div>
                            </div>
                            <div class="price_item">
                                <div class="lable">
                                    <span>{{ $t('VisitExhibition_youhui')  }} </span>
                                </div>
                                <div  class="item">
                                    <div>
                                        <p>{{ $t('VisitExhibition_gn_price') }}</p>
                                        <small>¥</small> 
                                        <span>2000</span>
                                        <small>RMB</small>
                                    </div>
                                    <div>
                                        <p>{{ $t('VisitExhibition_hw_price') }}</p>
                                        <small>$</small> 
                                        <span>300</span>
                                        <small>USD</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 立即报名 -->
                        <div class="btn_box">
                            <button class="btn_left" @click="Sigfree">{{$t('VisitExhibition_Sigfree') }}</button>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="header">
                        <img :src="online" alt="">
                        <span>{{ $t('VisitExhibition_right_title') }}</span>
                    </div>
                    <div class="desc">{{ $t('VisitExhibition_quanyi') }}</div>
                    <div class="c_box">
                        <div class="c_item" v-for="(item,m) in content.right" :key="m">
                            <div>
                                <i class="el-icon-check" style="color: #FFFFFF;"></i>
                            </div>
                            <span style="color: #FFFFFF;">{{item}}</span>
                        </div>
                    </div>
                    <!-- 免费报名 -->
                    <div class="btn_box">
                        <button class="btn_right" @click="Sigfree">{{ $t('VisitExhibition_Sigfree') }}</button>
                    </div>
                </div>

            </div>
            <!-- 为何观展 -->
            <div class="wh_box">
                <h1 class="title tit">{{$t('VisitExhibition_weihe_title')}}</h1>
                <div class="msg ti2" style="padding-bottom:20px"><span class="fw600">{{$t('meetingBrief1_1')}}</span>{{$t('meetingBrief1_2')}}<span  class="fw600">{{$t('meetingBrief1_3')}}</span>{{$t('meetingBrief1_4')}}</div>
                <div class="msg ti2">{{$t('meetingBrief2_1')}}<span class="fw600">{{  $t('meetingBrief2_2') }}</span>{{  $t('meetingBrief2_3') }}</div>
            </div>
        </div>
        <!-- 线下展会亮点 -->
        <div class="containerBox_page">
            <div class="bannerContent">
                <div class="bannerInner hightBox"  :style="{ 'background-image': 'url(' + Highlights.bgImg + ')' }">
                    <h1 class="tit">{{ $t('VisitExhibition_Highlights_off_title') }}</h1>
                    <div class="itemBox">
                        <div class="item" v-for="(item,i) in Highlights.items" :key="i">
                            <img :src="item.img" alt="">
                            <span>{{ item.lable }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 线上展会亮点 -->
        <div class="onLineBox">
            <h1 class="tit">{{ $t('VisitExhibition_Highlights_on_title') }}</h1>
            <div class="itemBox">
                <div class="item" v-for="(item,i) in Highlights_onLine.items.slice(0,3)" :key="i">
                    <img :src="item.img" alt="">
                    <span>{{ item.lable }}</span>
                </div>
            </div>
            <div class="itemBox itemBox_2">
                <div class="item" v-for="(item,i) in Highlights_onLine.items.slice(3,5)" :key="i+'_'" :class="{isLift:i==0}">
                    <img :src="item.img" alt="">
                    <span>{{ item.lable }}</span>
                </div>
            </div>
        </div>
        <applyInfoDialog
            :visible.sync="isApplyInfo"
            @close="radio = ''"
            v-if="isApplyInfo"
        ></applyInfoDialog>
    </div>
</template>
<script>
import banner from '~exp/components/common/banner';
import applyInfoDialog from "~exp/components/common/applyInfoDialog";
export default {
    components:{banner,applyInfoDialog},
    data(){
        return{
            isApplyInfo:false,
            online:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/xsqy.png',
            offline:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/xxqy.png',
            title:{
                offline:this.$t('VisitExhibition_left_title'),
                online:this.$t('VisitExhibition_right_title'),
                desc:this.$t('VisitExhibition_quanyi'),
            },
            content:{
                left:[
                    this.$t('VisitExhibition_left_item2'),
                    this.$t('VisitExhibition_left_item3'),
                    this.$t('VisitExhibition_left_item4'),
                    this.$t('VisitExhibition_left_item5'),
                ],
                right:[
                    this.$t('VisitExhibition_right_item1'),
                    this.$t('VisitExhibition_right_item2'),
                    this.$t('VisitExhibition_right_item3'),
                    this.$t('VisitExhibition_right_item4'),
                    this.$t('VisitExhibition_right_item5'),
                    this.$t('VisitExhibition_right_item6'),
                ]
            },
            
            //线下展会亮点
            Highlights:{
                bgImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/bg.png',
                items:[
                    {
                        lable:this.$t('off_gnzq'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights01.png',
                    },
                    {
                        lable:this.$t('off_zxdjh'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights02.png',
                    },
                    {
                        lable:this.$t('off_zxdjh'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights03.png',
                    },
                    {
                        lable:this.$t('off_hywy'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights04.png',
                    },
                    {
                        lable:this.$t('off_hwzq'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights05.png',
                    },
                    {
                        lable:this.$t('off_hwzq_min'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/Highlights06.png',
                    }
                ]
            },
            //线上展会亮点
            Highlights_onLine:{
                items:[
                    {
                        lable:this.$t('VisitExhibition_Highlights_on_item1'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/xszhld01.png',
                    },
                    {
                        lable:this.$t('VisitExhibition_Highlights_on_item2'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/xszhld02.png',
                    },
                    {
                        lable:this.$t('VisitExhibition_Highlights_on_item3'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/xszhld03.png',
                    },
                    {
                        lable:this.$t('VisitExhibition_Highlights_on_item4'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/xszhld04.png',
                    },
                    {
                        lable:this.$t('VisitExhibition_Highlights_on_item5'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/guanzhan/xszhld05.png',
                    },
                ]
            }
        }
    },
    methods:{
        //免费报名
        Sigfree(){
            //查看是否登录
            if(!this.IS_LOGIN){
                this.$router.push("/sign")
                return
            }
            //查看是否报名
            if(JSON.stringify(this.ENROLL_INFO) == "{}" || this.ENROLL_INFO.audit_status==="" ){    // 未报名
                this.$router.push("/register");
                return
            }else{  //已报名
                if(this.ENROLL_INFO.audit_status==1){  //已通过 
                    this.$message.success(this.$t('registerSuccessthank'))
                }else{   //待审核 && 已拒绝
                    this.isApplyInfo=true
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .VisitExhibition{
        width: 1300px;
        margin: auto;
        .tit{
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            text-align: center;
        }
        .guanzhan{
            display:flex;
            align-items: center;
            padding-top: 50px;
            .left{
                width: 650px;
                height: 780px;
                border-radius: 11px;
                box-shadow: 2px 3px 15px 0px rgba(17, 65, 109, 0.19);
                border-radius: 11px;
                border: 1px solid #C8CDD0;
            }
            .right{
                width: 650px;
                height: 710px;
                background: #1890FF;
                border-radius: 0px 11px 11px 0px;
            }
            .header{
                padding-top: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 60px;
                    height: 58px;
                }
                span{
                    padding-left: 20px;
                    font-size: 30px;
                    font-weight: 800;
                    color: #1F292E;
                }
            }
            .desc{
                margin-top: 40px;
                margin-bottom: 20px;
                width: 517px;
                height: 44px;
                background: linear-gradient(270deg, rgba(249, 255, 249, 0) 0%, #F1FFF0 100%);
                
                padding-left: 38px;
                font-size: 18px;
                font-weight: 400;
                color: #29B420;
                line-height: 44px;

            }
            /* 公共的样式 */
            .c_box{
                padding: 0 25px;
                
            }
            .c_item{
                display: flex;
                padding-bottom: 36px;
                padding-left: 117px;
                cursor: pointer;
                div{
                    position: relative;
                    width: 18px;
                    height: 13px;
                    i{
                        color: #28CD1E;
                        font-weight: bold;
                        position:absolute;
                        top: 5px;
                    }
                }
                span{
                    font-size: 14px;
                    font-weight: 400;
                    color: #1F292E;
                    line-height: 20px;
                    text-align: left;
                    width: 417px;
                    margin-left: 17px;
                }
            }
            .price_box{
                display: flex;
                padding-top: 30px;
                .price_item{
                    width: 300px;
                    padding-left: 43px;
                }
                .lable{
                    font-size: 18px;
                    font-weight: 400;
                    color: #7D8695;
                    padding-bottom: 20px;
                }
                .item>div{
                    display: flex;
                    align-items: baseline;
                    padding-bottom: 20px;
                    p{   
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;
                        padding-right: 12px;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 800;
                        color: #DD9740;
                        padding-right: 5px;
                    }
                    span,small{
                        color: #DD9740;
                    }
                }
            }
            .btn_box{
                display: flex;
                justify-content: center;
                padding-top: 40px;
                button{
                    width: 236px;
                    height: 50px;
                    cursor: pointer;
                    font-size: 24px;
                    font-weight: 800;
                    border: 0;
                }   
                
            }
            .btn_left{
               background: #1890FF;
               color: #FFFFFF;
            }
            .btn_right{
                background:#FFFFFF;
                color:  #1890FF;
            }
        }
        .wh_box{
            padding-top: 60px;
            padding-bottom: 73px;
            .title{
                padding-bottom: 24px;
            }
            .msg{
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
            }
        }
    }
    .containerBox_page {
        width: 100%;
        min-height: 781px;
        padding-top: 40.79%;
        position: relative;
        .bannerContent {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            .bannerInner {
            height: 100%;
            background-size: 100% 100%;
            position: relative;
            }
        }
        .img {
            width: 100%;
        }
    }
    .hightBox{
        
        .tit{
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            text-align: center;
            padding-bottom: 29px;
            padding-top: 50px;
        }
    }
    .itemBox{
        width: 1300px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            span{
                height: 58px;
                line-height: 45px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }
        }
    }
    .itemBox_2{
        display: flex;
        justify-content: center;
        .isLift{
            padding-right: 26px;
        }
    }
    .onLineBox{
        .tit{
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            text-align: center;
            padding-bottom: 29px;
            padding-top: 50px;
        }
        padding-bottom: 113px;
    }
</style>